import {
  VISITOR_INFO_PAGE_VIEWED,
  VISITOR_INFO_WHATSAPP_CLICKED,
  VISITOR_INFO_PHONE_CLICKED,
  VISITOR_INFO_LAST_VISITS_CARD_SEND_OFFER_CLICKED,
} from 'Visitor/State/constants';
import { PAGE_NAMES } from '../constants';
import { immutablePage } from '../utils';

export const handleVisitorInfoActions = (eventDispatcherHandler, action) => {
  switch (action.type) {
    case VISITOR_INFO_PAGE_VIEWED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'visitorinfo_page_viewed',
        immutablePage(PAGE_NAMES.VISITOR_INFO),
        action.extraProperties,
      );
      break;
    case VISITOR_INFO_WHATSAPP_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'visitorinfo_whatsapp_clicked',
        immutablePage(PAGE_NAMES.VISITOR_INFO),
        action.extraProperties,
      );
      break;
    case VISITOR_INFO_LAST_VISITS_CARD_SEND_OFFER_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'visitorinfo_last_visits_card_send_offer_clicked',
        immutablePage(PAGE_NAMES.VISITOR_INFO),
        action.extraProperties,
      );
      break;
    case VISITOR_INFO_PHONE_CLICKED:
      eventDispatcherHandler.logWithDefaultEventProps(
        'visitorinfo_phone_clicked',
        immutablePage(PAGE_NAMES.VISITOR_INFO),
        action.extraProperties,
      );
      break;
    default:
      break;
  }
};

export default handleVisitorInfoActions;
